.cat-nav {
	width: 100%;
	font-size: 0;
	background-color: #fff;
	border-top: 1px solid #f4f4f4;
	border-bottom: 1px solid #f4f4f4;
	@include respond-to('small') {
		text-align: center;
	}
	@include respond-to('extra-small') {
		// height: 120px;
	}

	& .section-limit {
		height: 100%;
	}
	&__item {
		// width: 12.4%;
		height: 100%;
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		overflow: hidden;
		@include respond-to('small') {
			// width: 33.33%;
			// height: 165px;
		}
		@include respond-to('extra-small') {
			// width: 50%;
			// height: 112px;
			&:last-of-type {
				// width: 100%;
			}
		}
	}
}

.cat-item {
	// height: 100%;
	height: 220px;
	padding: 30px 15px;
	text-align: center;
	border-#{$left}: 1px solid #f4f4f4;
	@include respond-to('extra-small') {
		// padding: 20px 10px;
	}
	@include respond-to('mobile-m') {
		// padding: 10px 5px;
		height: 250px;
	}
	&:hover,
	&:focus,
	&:active {
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
		text-decoration: none;
	}
	&_bord-left {
		&:first-child {
			border-#{$right}: 1px solid none;
		}
	}
	&__icon {
		height: 86px;
		vertical-align: middle;
		@include respond-to('large') {
			// height: 75px;
		}
		@include respond-to('extra-small') {
			// height: 60px;
		}
		& img {
			vertical-align: middle;
			display: inline-block;
			// height: auto;
			margin: 0 auto;
			// max-width: 85px;
		}
	}
	&__title {
		overflow: hidden;
		max-height: 60px;
		margin-top: 17px;
		margin-bottom: 13px;
		font-size: 20px;
		font-weight: 700;
		line-height: 1;
		color: $accent;
		@include respond-to('extra-small') {
			margin-top: 6px;
			font-size: 18px;
		}
	}
	&__desc {
		overflow: hidden;
		max-height: 95px;
		color: $text;
		font-size: 16px;
		font-weight: 300;
		text-align: center;
		line-height: 1.2;
		& p {
			margin: 0;
		}
	}
}

.cat-nav-slider {
	border-#{$right}: 1px solid #f4f4f4;
	border-#{$left}: 1px solid #f4f4f4;
	// margin: 0 -15px;
	margin-#{$right}: -1px;
	& .slick-list {
		// padding: 0 15px;
	}
	& .slick-track {
		// width: 100% !important;
	}
	& .slick-next {
		left: -25px;
		@include respond-to('1550') {
			left: -15px;
		}
		&::before {
			color: $accent;
		}
	}
	& .slick-prev {
		right: -25px;
		@include respond-to('1550') {
			right: -15px;
		}
		&::before {
			color: $accent;
		}
	}
}