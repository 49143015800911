.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background-color: transparent;
	background-image: url(../images/header-back.png);
	background-position: center bottom;
	transition: height 0.1s ease, background 0.5s ease;
	@include respond-to('medium') {
		transition: all 0.4s ease;
	}
}

.header-desktop {
	height: 185px;
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
	}
}

.login {
	margin-#{$left}: 350px;
	margin-top: 30px;
	float: $left;
	transition: margin 0.2s ease;
	@include respond-to('1550') {
		margin-#{$left}: 300px;
	}
	@include respond-to('huge') {
		margin-#{$left}: 270px;
	}
	@include respond-to('large') {
		margin-#{$left}: 210px;
	}
	@include respond-to('1100') {
		margin-#{$left}: 180px;
	}
	& a {
		display: block;
		padding: 9px 12px 8px;
		border: 1px solid $accent;
		@include link_no-hover($text);
		line-height: 1;
		& span {
			color: $text;
			font-size: 16px;
			font-weight: 900;
			@include respond-to('large') {
				display: none;
			}
		}
		& i.users {
			display: none;
			@include respond-to('large') {
				display: block;
				font-size: 25px;
			}
		}
	}
}

.logo {
	position: absolute;
	top: 16px;
	#{$left}: 8px;
	transition: all 0.2s ease;
	& a {
		display: block;
		@include link_no-hover($text);
		& img {
			max-width: 177px;
			transition: all 0.2s ease;
			@include respond-to('1550') {
				max-width: 270px;
			}
			@include respond-to('huge') {
				max-width: 250px;
			}
			@include respond-to('large') {
				max-width: 200px;
			}
			@include respond-to('1100') {
				max-width: 170px;
			}
		}
		& span {
			text-align: left;
			display: block;
			font-size: 25px;
			font-weight: 900;
			line-height: 25px;
			text-transform: uppercase;
			transition: font-size 0.2s ease;
		}
	}
}

.main-navi {
	display: inline-block;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(37px 0px 0 0);
		padding: 0;
		transition: margin 0.2s ease;
		& > li {
			float: $right;
			margin-#{$left}: 6px;
			position: relative;
			@include respond-to('huge') {
				margin-#{$left}: 3px;
			}
			&:last-child {
				margin-#{$left}: 0;
			}
			& > a {
				font-size: 18px;
				color: #4a4b4d;
				font-weight: 700;
				line-height: 32px;
				padding: 0px 4px 1px;
				display: block;
				transition: color 0.2s ease, background 0.2s ease;
				@include respond-to('huge') {
					font-size: 16px; 
				}
				@include respond-to('larger') {
					padding: 0 3px 1px;
    				font-size: 15px; 
				}
				@include respond-to('1100') {
					font-size: 14px;
				}
				&:hover {
					text-decoration: none;
					background-color: $accent;
					color: #fff;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					background-color: $accent;
					color: #fff;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 10px;
		float: $left;
		& > img {
			width: 170px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $text;
			font-size: 32px;
		}
	}
}

.main-header.scrolling {
	background-color: #fff;
	background-image: none;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
	.header-desktop {
		height: 80px;
	}
	.main-navi {
		& > ul {
			margin-top: 25px;
		}
	}
	.login {
		float: $left;
		margin-top: 15px;
		margin-#{$left}: 223px;
		@include respond-to('large') {
			margin-top: 18px;
			margin-#{$left}: 180px;
		}
	}
	.logo {
		top: 6px;
		#{$left}: 15px;
		& img {
			max-width: 177px;
			@include respond-to('large') {
				max-width: 170px;
			}
		}
		& span {
			font-size: 17px;
			line-height: 1;
		}
	}
}

.main-navi {
	display: inline-block;
	& > ul {
	
		& > li {
			&:hover {
				& > ul {
					display: block;
					opacity: 1;
					transition: 0.4s;
				}
			}
			&:last-child {
				& > span {
					opacity: 0;
					display: none;
				}
			}
			& > a {
			
			}
			& > ul {
				padding: 0;
				margin: 0;
				list-style: none;	
				background-color: rgba(255, 255, 255, 0.85);;
				position: absolute;
				top: 31px;
				#{$right}: 0;
				z-index: 9;
				font-size: 20px;
				opacity: 0;
				display: none;
				transition: 0.4s;
				min-width: 285px; 
				text-align: right;
				//   border-left: 1px solid #3d5184;
				@include respond-to('large') {
					top: 31px;
				}
				& > li {
					&:hover {
						background-color: #fff;
					}
					border-bottom: 1px solid #f3f3f3;
					position: relative;
					& > a {
						border-bottom: 1px solid #9b8234;
						transition: 0.3s;
						color: #000;
						padding: 7px 12px;
						display: block;
						opacity: 1;
						&:hover {
							text-decoration: none !important;
						}
					}
					& > ul {
						padding: 0;
						margin: 0;
						list-style: none;
						background-color: #ffffff;
						position: absolute;
						top: 0;
						transition: 0.4s;
						right: 285px;
						z-index: 9;
						opacity: 0;
						display: none;
						min-width: 300px;
						text-align: center;
						& > li {
							//border-bottom: 1px solid #3a4f82;
							position: relative;
							&:hover {
								background-color: #f4f4f4;
								text-decoration: none !important;
							}
							& > a {
								&:hover {
									text-decoration: none !important;
								}
								transition: 0.3s;

								color: #000;
								padding: 7px 12px;
								display: block;
								opacity: 1;
								border-bottom: 1px solid #9b8234;
								text-align: right;
							}
							& > ul { 
								padding: 0 20px;
								margin: 0;
								list-style: none;
								background-color: #ffffff;
								position: absolute;
								top: 0;
								transition: 0.4s;
								right: 314px;
								z-index: 9;
								opacity: 0;
								display: none; 
								min-width: 315px;
								text-align: center;
								& > li {
									position: relative;
									float: right;
									width: 50%;
									&:nth-child(2n+1) {
										clear: both;
									}
									& > a {
										transition: 0.3s;
										color: #2d2a6e;
										padding: 12px 0px;
										display: block;
										opacity: 1;
										&:hover {
											color: #002c5c;
										}
									}
								}
							}
							&:hover {
								& > ul {
									transition: 0.3s;
									display: block;
									opacity: 1;
								}
							}
						}
					}
					&:hover {
						& > ul {
							transition: 0.3s;
							display: block;
							opacity: 1;
						}
					}
				}

			} 
		}
	}
}


.fa-chevron-left{
	position: absolute;
    left: 12px;
}
a.btnh {
    border: 1px solid #9b8234; 
    margin: -6px 0;
    padding: 3px 9px !important;
}