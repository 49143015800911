.investors {
	padding: 40px 0 0;
	text-align: center;
	background-color: #f9f9f9;
	@include respond-to('medium') {
		padding-top: 20px;
	}
	&__title {
		padding-bottom: 35px;
		border-bottom: 1px solid #d5d5d5;
		color: $accent;
		font-size: 30px;
		font-weight: 900;
		line-height: 0.9;
		@include respond-to('medium') {
			padding-bottom: 20px;
		}
	}
	&__slider {
		padding: 24px 0;
		@include respond-to('medium') {
			padding: 15px 0;
		}
	}
	&__slide-item {
		display: none;
		position: relative;
		height: 120px;
		min-width: 165px;
		line-height: 120px;
		@include respond-to('extra-small') {
			height: 100px;
			line-height: 100px;
		}
		&:active,
		&:focus {
			outline: none;
		}
		&:first-child {
			display: block;
		}
		&:hover {
			& img {
				// filter: grayscale(100%);
			}
		}
		& > img {
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
			display: inline;
			max-width: 90%;
			max-height: 90%;
		}
	}
}

#clients-slide {
	& .slick-arrow {
		width: 12px;
		height: 46px;
	}
	& .slick-prev {
		#{$right}: -10px;
		&::before {
			content: if-rtl(url('../images/arr-right.png'), url('../images/arr-left.png'));
		}
	}
	& .slick-next {
		#{$left}: -10px;
		&::before {
			content: if-rtl(url('../images/arr-left.png'), url('../images/arr-right.png'));
		}
	}
}

// @media (max-width: 1750px) {
// 	#clients-slide {
// 		& .slick-prev {
// 			// #{$right}: -15px;
// 		}
// 		& .slick-next {
// 			// #{$left}: -15px;
// 		}
// 	}
// }


// @media (max-width: 570px) {
// 	#clients-slide {
// 		& .slick-prev {
// 			#{$right}: 10px;
// 		}
// 		& .slick-next {
// 			#{$left}: 10px;
// 		}
// 	}
// }