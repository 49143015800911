.ftr-links-koteret {
	color: $text;
	font-size: 18px;
	font-weight: 900;
	margin-bottom: 25px;
	padding-bottom: 20px;
	border-bottom: 1px solid #d1d1d1;
	position: relative;
	@include respond-to('medium') {
		margin-bottom: 15px;
		padding-bottom: 10px;
		font-size: 16px;
	}
	@include respond-to('extra-small') {
		font-size: 14px;
		// margin-bottom: 15px;
		// padding-bottom: 10px;
	}
	&::after {
		content: " ";
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		width: 0%;
		height: 1px;
		background-color: $text;
		margin: 0 auto;
		transition: all 0.4s;
	}
}

.ftr-wrap-links {
	@include respond-to('small') {
		margin-bottom: 10px;
	}
	&:hover {
		div:after {
			width: 100%;
		}
	}
	& > ul {
		padding: 0;
		margin: 0;
		list-style: none;
		& > li {
			margin-bottom: 5px;
			@include respond-to('extra-small') {
				margin-bottom: 2px;
				line-height: 1;
			}
			& > a {
				color: #9b8234;
				font-size: 16px;
				font-weight: 400;
				@include respond-to('extra-small') {
					font-size: 12px;
				}
				&:hover {
					color: $text;
					text-decoration: none;
				}
			}
		}
	}
}