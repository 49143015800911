.wrap-team-box {
	display: block;
	&:hover {
		cursor: pointer;
		.team-box-image__item {
			transform: scale(1.1);
		}
	}
	& hr {
		margin: 50px 0;
		border-top: 1px solid #c6c6c6;
		@include respond-to('medium') {
			margin: 20px 0;
		}
	}
	&:last-child {
		& hr {
			display: none;
		}
	}
}

.team-box-image {
	max-width: 217px;
	width: 100%;
	height: 156px;
	overflow: hidden;
	// padding: 9px;
	border: 1px solid $accent;
	@include respond-to('small') {
		// max-width: 300px;
		margin: 0 auto;
	}
}

.team-box-image__item {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	transition: all 0.6s;
}

.team-box-data {
	position: relative;
	// padding-#{$right}: 20px;
	@include respond-to('medium') {
		// padding-#{$right}: 0;
	}
	@include respond-to('small') {
		margin-top: 15px;
	}
	&__title {
		font-size: 25px;
		font-weight: 900;
		color: $accent;
		line-height: 1;
		height: 45px;
		@include respond-to('small') {
			font-size: 22px;
		}
	}
	// &__subtitle {
	// 	font-size: 20px;
	// 	font-weight: 400;
	// 	color: #000;
	// 	line-height: 1;
	// 	@include respond-to('small') {
	// 		font-size: 18px;
	// 	}
	// 	@include respond-to('extra-small') {
	// 		margin-bottom: 15px;
	// 	}
	// }
	&__content {
		overflow: hidden;
		margin-top: 10px;
		text-align: justify;
		&_mw {
			max-width: 590px;
		}
		& > p {
			font-size: 20px;
			font-weight: 400;
			color: $text;
			margin: 0;
			line-height: 1.2;
			@include respond-to('small') {
				font-size: 18px;
			}
			@include respond-to('extra-small') {
				font-size: 16px;
			}
		}
	}
	&__link {
		display: inline-block;
		margin-top: 40px;
		border-top: 3px solid $accent;
		font-weight: 900;
		padding-top: 6px;
		font-size: 18px;
		@include link_no-hover($accent);
		@include respond-to('extra-small') {
			margin-top: 20px;
		}
	}
}


.card-img-top{
	width: 100%; 
	height: auto; 
	border: 1px solid #9b8234;
}

.card-body{
	border: 1px solid #9b8234;
	border-top: none;
	padding: 0 10px 30px 10px;
}
.card{
	margin-bottom: 13px;
}