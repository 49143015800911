.breadcrumb {
	background-color: transparent;
	margin-bottom: 0;
	padding: 15px 0;
	border-bottom: 1px solid #d9d9d9;
	border-radius: 0;
	@include respond-to('small') {
		// display: none;
	}
	& li {
		font-size: 20px;
		font-weight: 400;
		&::before {
			color: #aeaeae;
		}
		& a {
			color: #aeaeae;
			text-decoration: none;
		}
	}
	& .active {
		color: #4a4b4d;
	}
}