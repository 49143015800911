.new-gallery {
	padding-top: 20px;
	padding-bottom: 20px;
}

.gallery-item {
	width: 20%;
	float: right;
	position: relative;
	display: block;
	overflow: hidden;
	height: 280px;
	text-align: center;
	background-position: center;
	background-size: cover;
	@include respond-to('large') {
		height: 300px; 
	}
	@include respond-to('medium') {
		width: 20%;
		height: 250px;
	}
	@include respond-to('extra-small') {
		width: 100%;
		height: 200px;  
	}
	&_long {
		width: 20%;
		@include respond-to('extra-small') {
			width: 100%;
		}
	} 
	&:hover {
		.gallery-item__img {
			transform: scale3d(1.1, 1.1, 1.1);
		}
		.gallery-item__overlay {
			opacity: 1;
		}
	}
	&__img {
		display: block;
		max-width: 100%;
		height: 100%;
		width: 100%;
		transform: scale3d(1, 1, 1);
		transition: transform 0.15s ease 0s;
	}
	&__overlay {
		// position: absolute;
		// top: 0;
		// left: 0;
		// width: 100%;
		// height: 100%;
		// background-color: rgba(255, 255, 255, 0.24);
		// opacity: 1;
		// transition: opacity 0.15s ease 0s;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& i {
			font-size: 40px;
			color: #fff;
		}
	}
	&__text {
		position: absolute;
		bottom: 35px;
		left: 50%;
		display: block;
		width: 70%;
		text-shadow: 0 0 3px #000; 
		transform: translateX(-50%);
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 300;
	}
}