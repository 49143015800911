.wrapper-banners {
	position: relative;
	width: 100%;
	height: 720px;
	overflow: hidden;
	@include respond-to('huge') {
		// height: 750px;
	}
	@include respond-to('larger') {
		height: 650px;
	}
	@include respond-to('large') {
		height: 550px;
	}
	@include respond-to('medium') {
		height: 500px;
	}
	@include respond-to('extra-small') {
		height: 350px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 720px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		// min-height: 750px;
	}
	@include respond-to('larger') {
		min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 550px;
	}
	@include respond-to('medium') {
		min-height: 500px;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.3);
		}
	}
	@include respond-to('extra-small') {
		min-height: 350px;
	}

	&__text {
		position: absolute;
		z-index: 1;
		#{$left}: 265px;
		top: 50%;
		transform: translateY(-50%);
		@include respond-to('1550') {
			#{$left}: 90px;
		}
		@include respond-to('medium') {
			top: auto;
			bottom: 15px;
			transform: translateY(0);
		}
		@include respond-to('small') {
			#{$left}: auto;
			#{$right}: 15px;
		}
	}
}

.banner-text {
	max-width: 350px;
	width: 100%;
	@include respond-to('extra-small') {
		max-width: 290px;
	}
	&__logo {
		max-width: 181px;
	}
	&__title {
		padding-top: 5px;
		padding-bottom: 5px;
		color: $text;
		font-size: 44px;
		font-weight: 900;
		line-height: 0.9;
		text-shadow: 0 0 3px #fff;
		@include respond-to('small') {
			font-size: 35px;
		}
	}
	&__text {
		padding-bottom: 10px;
		color: $text;
		font-size: 27px; 
		line-height: 27px;
		font-weight: 400;
		text-shadow: 0 0 3px #fff;
		@include respond-to('medium') {
			color: #fff;
		text-shadow: 0 0 3px #000;

		}
		@include respond-to('small') {
			// font-weight: 400;
		}
		& p {
			margin: 0;
		}
	}
	&__btn {
		display: inline-block;
		padding: 8px 14px;
		border-radius: 4px;
		background-color: $accent;
		font-size: 20px;
		font-weight: 700;
		@include link_no-hover(#fff);
		&:active {
			@include btn-effect-active;
		}
	}
}