.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}

.hp-join-us {
	min-height: 700px;
	padding-top: 80px;
	padding-bottom: 40px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include respond-to('larger') {
		padding-top: 40px;
		min-height: 600px;
	}
	@include respond-to('large') {
		min-height: auto;
	}
	@include respond-to('small') {
		background-image: none !important;
		padding-top: 0px;
	}
	&__img {}
	&__list {} 
}

.advantages {
	padding-#{$right}: 90px;
	@include respond-to('larger') {
		padding-#{$right}: 40px;
	}
	@include respond-to('1100') {
		padding-#{$right}: 0px;
	}
	@include respond-to('small') {
		padding-top: 20px;
	}
	&__title {
		color: #ffffff;
		font-size: 50px;
		font-weight: 900;
		line-height: 1.2;
		@include respond-to('large') {
			font-size: 40px;
		}
		@include respond-to('1100') {
			font-size: 35px;
		}
		@include respond-to('small') {
			color: $text;
		}
		@include respond-to('extra-small') {
			font-size: 30px;
		}
	}
	&__subtitle {
		margin-bottom: 44px;
		color: $accent;
		font-size: 30px;
		font-weight: 400;
		line-height: 1;
		@include respond-to('large') {
			// font-size: 25px;
			margin-bottom: 25px;
		}
		@include respond-to('extra-small') {
			font-size: 25px;
		}

	}
	&__items {
		& ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		& li {
			list-style: none;
			position: relative;
			padding-#{$right}: 38px;
			color: #fff;
			font-size: 20px;
			font-weight: 400;
			line-height: 30px;
			@include respond-to('1100') {
				font-size: 18px;
				line-height: 25px;
			}
			@include respond-to('small') {
				color: $text;
			}
			@include respond-to('extra-small') {
				padding-#{$right}: 20px;
			}
			&::after {
				content: url(../images/list-decor.png);
				position: absolute;
				#{$right}: 0;
				top: 5px;
				@include respond-to('1100') {
					top: 1px;
				}
			}
		}
	}
}

.sec-padd {
	padding: 85px 0;
	@include respond-to('larger') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 20px 0;
	}
	&_2 {
		padding: 128px 0 122px;
		@include respond-to('larger') {
			padding: 40px 0;
		}
		@include respond-to('small') {
			padding: 20px 0;
		}
	}
}

.hp-steps {
	background-color: #fff; 
	text-align: center; 
	padding-bottom: 120px;
	@include respond-to('larger') {
		padding-bottom: 50px;
	}
	@include respond-to('large') {
		padding-bottom: 40px;
	}
	@include respond-to('small') {
		padding-bottom: 20px;
	}
	&__title1 {
		color: $accent;
		font-size: 50px;
		font-weight: 900;
		line-height: 0.8;
		text-transform: uppercase;
		@include respond-to('large') {
			font-size: 40px;
		}
		@include respond-to('1100') {
			font-size: 35px;
		}
		@include respond-to('small') {
			font-weight: 700;
		}
		@include respond-to('extra-small') {
			font-size: 30px;
		}
	}
	&__title2 {
		color: $text;
		font-size: 40px;
		font-weight: 900;
		line-height: 1.2;
		text-transform: uppercase;
		@include respond-to('large') {
			font-size: 35px;
		}
		@include respond-to('1100') {
			font-size: 30px;
		}
		@include respond-to('small') {
			font-weight: 700;
		}
		@include respond-to('extra-small') {
			font-size: 25px;
		}
	}
	&__desc {
		max-width: 765px;
		width: 100%;
		margin: 0 auto;
		color: $text;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.2;
		@include respond-to('1100') {
			font-size: 25px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
		& p {
			margin: 0;
		}
	}
	&__steps {
		@include respond-to('1550') {
			padding-#{$right}: 15px;
			padding-#{$left}: 15px;
		}
		@include respond-to('1100') {
			padding-#{$right}: 0px;
			padding-#{$left}: 0px;
		}
	}
	&__link {
		margin-top: 40px;
		display: inline-block;
		padding-bottom: 14px;
		border-bottom: 3px solid #e8d695;
		font-size: 20px;
		font-weight: 900;
		transition: color 0.2s ease, border 0.2s ease;
		@include link_no-hover($accent);
		@include respond-to('large') {
			margin-top: 20px;
		}
	}
}




.steps {
	font-size: 0;
	padding-top: 30px;
	margin: 0 -100px;
	direction: ltr;
	@include respond-to('huge') {
		margin: 0 -70px;
	}
	@include respond-to('1100') {
		margin: 0;
	}
	&__item {
		position: relative;
		display: inline-block;
		// float: left;
		max-width: 20%;
		width: 100%;
		font-size: initial;
		@include respond-to('small') {
			max-width: 100%;
		}
		@include respond-to('extra-small') {
			margin-bottom: 15px;
		}
		&:last-child {
			& .number__decor {
				display: none;
			}
		}
		&:nth-child(even) {
			& .number__decor {
				display: none;
			}
		}
	}
	&__item-number {
		text-align: center;
	}
	&__item-desc {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 130px;
		color: $text;
		font-size: 20px;
		font-weight: 900;
		line-height: 20px;
		@include respond-to('1100') {
			position: static;
			transform: translate(0%, 0%);
			margin: 0 auto;
		}
		@include respond-to('medium') {
			font-size: 18px;
		}
		@include respond-to('small') {
			padding: 0 5px;
			font-size: 16px;
		}
	}
}

.number { 
	height: 320px; 
	@include respond-to('1100') {
		// height: 140px;
	}
	@include respond-to('extra-small') {
		// height: 85px;
	}
	&__step {
		pointer-events: none;
		// display: block;
		color: #ba9f4a;
		background: linear-gradient(-220deg, #b08b3d 0%, #b08b3d 30%, #f2d880 60%, #b08b3d 80%, $accent 100%);
		-webkit-text-fill-color: transparent;
		background-clip: text;
		font-size: 300px;
		font-weight: 400;
		user-select: none;
		line-height: 0.7;
		@include respond-to('1100') {
			font-size: 150px;
		}
		@include respond-to('extra-small') {
			font-size: 100px;
		}
	}
	&__decor {
		position: absolute;
		#{$right}: -30px;
		top: 50%;
		width: 60px;
		height: 2px;
		background-color: #cecece;
		@include respond-to('1100') {
			top: 32%;
		}
		@include respond-to('small') {
			#{$right}: -15px;
			width: 30px;
		}
	}
}

.hp-about {
	&__item {
		position: relative;
		min-height: 700px;
		@include respond-to('larger') {
			min-height: 560px;
		}
		@include respond-to('large') {
			min-height: auto;
		}
		&_dark {
			background-color: #415667;
			background-image: linear-gradient(to top, #000000 0%, #48494b 100%);
		}
		&_light {
			background-color: #ebebeb;
		}
	}
}

.about-row {
	&_back {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		@include respond-to('small') {
			background-image: none !important;
		}
	}
	&__info {
		max-width: 760px;
		@include respond-to('small') {
			// padding-top: 20px;
		}
	}
	&__title {
		color: #ffffff;
		font-size: $font-size-title;
		font-weight: 900;
		line-height: 0.9;
		@include respond-to('large') {
			font-size: 40px;
		}
		@include respond-to('1100') {
			font-size: 35px;
		}
		@include respond-to('small') {
			font-weight: 700;
		}
		@include respond-to('extra-small') {
			font-size: 30px;
		}
		&_accent {
			color: $accent;
		}
	}
	&__subtitle {
		color: #9b8234;
		font-size: $font-size-large;
		font-weight: 400;
		line-height: 1;
		@include respond-to('small') {
			font-size: 25px;
		}
		&_grey {
			color: $text;
		}
	}
	&__desc {
		margin-top: 35px;
		@include respond-to('small') {
			margin-top: 20px;
		}
		& p {
			color: #ffffff;
			font-size: 20px;
			font-weight: 400;
			margin-bottom: 35px;
			@include respond-to('small') {
				font-size: 18px;
				margin-bottom: 15px;
			}
			@include respond-to('extra-small') {
				text-align: justify;
			}
		}
		&_grey {
			& p {
				color: $text;
			}
		}
		&_taj {
			text-align: justify;
		}
		&_pl {
			padding-#{$left}: 25px;
			@include respond-to('medium') {
				padding-#{$left}: 0;
			}
		}
	}
	&__link {
		// padding-top: 35px;
		&_pt {
			.hp-steps__link {
				margin-top: 5px;
			}
		}
	}
	&__img {
		@include respond-to('medium') {
			margin-bottom: 20px;
		}
		&_tal {
			text-align: $left;
			@include respond-to('medium') {
				text-align: center;
			}
		}
		&_pt {
			padding-top: 50px;
			@include respond-to('small') {
				padding-top: 30px;
			}
		}
	}
}



span.number__step_v2 {
    font-size: 100pt;
    font-family: "Times New Roman", Times, serif;
}
.top-text.steps__item-desc {
    top: 32%;
	left: 61%;
	position: absolute;
	@include respond-to('medium') {
		top: 25%;
		left: 40%;
	}
}
.down-text.steps__item-desc {
    top: 64%;
	left: 41%;
	position: absolute;
	@include respond-to('medium') {
		left: 20%;
	}
	
}
span.num-top.number__step_v2 {
	position: absolute;
	top: 6%;
    left: 23%;
}
span.num-down.number__step_v2 {
    position: absolute;
    left: 28%;
    top: 33%;
}



.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #000;
	opacity: 1; /* Firefox */
	font-weight: 800;
  }
  
  .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #000;
	font-weight: 800;
  }
  
  .form-control::-ms-input-placeholder { /* Microsoft Edge */
	color: #000; 
	font-weight: 800;   
  }  



