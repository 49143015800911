.content-block {
	&__right {
		float: $right;
		max-width: 77%;
		width: 100%;
		@include respond-to('larger') {
			max-width: 73%;
		}
		@include respond-to('1100') {
			max-width: 70%;
		}
		@include respond-to('medium') {
			max-width: 100%;
		}
	}
	&__left {
		float: $left;
		max-width: 23%;
		width: 100%;
		text-align: $left;
		// background-image: linear-gradient(to right, #ffffff 0%, #ffffff 90%, #ededed 100%);
		@include respond-to('larger') {
			max-width: 27%;
		}
		@include respond-to('1100') {
			max-width: 30%;
		}
		@include respond-to('medium') {
			display: none;
		}
	}
}

.wrap-content {
	padding: 0 0 20px;
	&_shadow {
		padding-#{$left}: 72px;
		box-shadow: if-rtl(-25px 0 10px rgba(0, 0, 0, .08), 25px 0 10px rgba(0, 0, 0, .08));
		min-height: 80vh;
		@include respond-to('medium') {
			box-shadow: none;
			padding-#{$left}: 0px;
		}
	}
}

.wrap-all-content-not-banner {
	margin-top: 140px;
}

.title {
	margin: 40 0 0 0;
	color: $accent;
	line-height: 0.9;
	font-size: $font-size-title;
	font-weight: 900;
	@include respond-to('large') {
		font-size: 40px;
	}
	@include respond-to('1100') {
		font-size: 35px;
	}
	@include respond-to('medium') {
		margin: 20px 0 0 0;
	}
	@include respond-to('extra-small') {
		font-size: 30px;
	}
	& span {
		display: block;
		padding-top: 10px;
	}
}

.subtitle {
	margin: 0 0 30px 0;
	color: $text;
	font-size: 30px;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 0.9;
	@include respond-to('1100') {
		font-size: 25px;
	}
	@include respond-to('medium') {
		margin: 0 0 20px 0;
	}
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 45px;
	@include respond-to('medium') {
		margin: 0 0 25px;
	}
}

.content-text {
	h2,
	h3 {
		font-size: 24px;
		font-weight: 900;
		line-height: 1.2;
		color: $accent;
		margin: 20px 0 30px;
		@include respond-to('extra-small') {
			font-size: 20px;
			margin: 10px 0 15px;
		}
	}
	p {
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 400;
		color: #4a4b4d;
		line-height: 1.2;
		@include respond-to('small') {
			font-size: 18px;
			text-align: justify;
		}
		@include respond-to('extra-small') {
			margin-bottom: 11px;
		}
		@include respond-to('mobile-l') {
			font-size: 16px;
		}
	}
	& ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li.m {
			height: 60px;
			padding-right: 70px;
			&::before {
				background: transparent url(../images/m.png) scroll 0 0 no-repeat;
				content:""; 
				width: 60px;
				height: 60px;
				position: absolute;
				left: 0;
				top: -13px;
			}
		}
		li.f {
			height: 60px;
			padding-right: 70px;
			&::before {
				background: transparent url(../images/f.png) scroll 0 0 no-repeat;
				content:"";
				width: 60px;
				height: 60px;
				position: absolute;  
				left: 0;
				top: -13px;
			}
		}
		li {
			position: relative;
			padding-#{$right}: 28px;
			font-size: 20px;
			@include respond-to('small') {
				font-size: 18px;
			}
			@include respond-to('mobile-l') {
				font-size: 16px;
			}
			&::before {
				content: '';
				position: absolute;
				right: 0;
				top: 11px;
				width: 6px;
				height: 6px;
				background-color: #9b8234;
			}
		}
	}
	a.custom-link {
		display: inline-block;
		margin-top: 25px;
		padding-top: 10px;
		border-top: 3px solid $accent;
		@include link_no-hover($accent);
		font-size: 20px;
		font-weight: 900;
		@include respond-to('small') {
			font-size: 18px;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}


.list-group-item{
	border-right: none;
	border-left: none;
	padding: 0;
}