.side-navigation-wrap {
	display: inline-block;
	max-width: 250px;
	width: 100%;
	padding: 83px 0 0 0;
	text-align: $right;
}

.side-nav-menu {
	margin-bottom: 130px;
	&__title {
		margin-bottom: 20px;
		color: $accent;
		font-size: 26px;
		font-weight: 900;
	}
	&__list {
		padding: 0;
		margin: 0;
		list-style: none;
		& li {
			border-bottom: 1px solid #e0e0e0;
			transition: border 0.2s ease;
			&:first-child {
				border-top: 1px solid #e0e0e0;
			}
			&:hover {
				border-bottom: 1px solid $accent;
				& a {
					color: $accent;
					border-bottom: 1px solid $accent;
				}
			}
			&.current {
				border-bottom: 1px solid $accent;
				& a {
					color: $accent;
					border-bottom: 1px solid $accent;
				}
			}
			& a {
				display: block;
				border-bottom: 1px solid transparent;
				padding: 8px 0;
				font-size: 18px;
				font-weight: 500;
				color: #4a4b4d;
				transition: color 0.2s ease, border 0.2s ease;
				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
			}
		}
	}
}