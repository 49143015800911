footer {
	width: 100%;
	position: relative;
	box-shadow: inset 3px 4px 20px rgba(0, 0, 0, 0.25);
	background-color: #f9f9f9;
	z-index: 1;
	padding: 70px 0 35px;
	@include respond-to('large') {
		padding: 40px 0 25px;
	}
	@include respond-to('medium') {
		padding: 25px 0 10px;
	}
}

.ftr-nav-col {
	float: $right;
	width: 20%;
	@include respond-to('small') {
		width: 33.33%;
	}
	@include respond-to('extra-small') {
		width: 50%;
	}
	&__item {
		max-width: 200px;
		margin: 0 auto;
		@include respond-to('1100') {
			padding: 0 10px;
		}
	}
}
.ftr-nav-col-form{
	@include respond-to('extra-small') {
		width: 90%;
		margin: 0 5%;
	}
}


.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #a9a9a9;
	margin-top: 40px;
	margin-bottom: 40px;
	@include respond-to('large') {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	@include respond-to('medium') {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.credit-info-txt {
	display: inline-block;
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}
	@include respond-to('small') {
		margin-right: 0;
	}
	& > p {
		margin: 0;
		color: $text;
		font-size: 20px;
		font-weight: 400;
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.wrap-social {
	position: relative;
	display: inline-block;
	margin-#{$right}: 25px;
	@include respond-to('large') {
		margin-#{$right}: 20px;
	}
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin: 0;
		margin-bottom: 10px;
	}
	& > a {
		& > i {
			font-size: 20px;
			color: $accent;
			margin-#{$left}: 15px;
		}
		&:hover {
			& > .fa-linkedin-in {
				color: $clr-linkedin;
			}
			& > .fa-youtube {
				color: $clr-youtube;
			}
			& > .fa-facebook-f {
				color: $clr-facebook;
			}
		}
	}
}

.site-footer__credit-holder {
	float: $left;
	@include respond-to('medium') {
		float: none;
	}
}